import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Icon from "../icon"

const VolunteeringProjectItem = (props) => {

   const { project } = props
   const { t } = useContext(LanguageContext)

   return (
     <div className="day-hour">
       <div className="ministry-label label">
            <Icon
              icon="icons-32-x-32-export-tag"
              className="icon m-r-12"
              size={24}
              color="grey-dark"
           />
           {t("Projecte")}
       </div>
       <div className="ministry-value m-b-22">
        {project}
       </div>
     </div>
   )

 }

 export default VolunteeringProjectItem
