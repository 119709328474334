import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import ProjectsPage from "../components/pages/projects-page"

const PageProjects = ({ data, location, pageContext }) => {

  const post = data?.nodePageGoel
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const events = data?.event
  const projects = data?.projects
  const promoted = data?.promoted
  const toknowus = data?.toknowus
  const volunteering = data?.volunteering

  const translationPaths = {
   en: `/en${post?.path?.alias}`,
   es: `/es${post?.path?.alias}`,
   ca: `${post?.path?.alias}`
 }

  return (
    <Layout
      location={location}
      langcode={post?.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post?.title}
        lang={post?.langcode}
        description={post?.body?.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />

       <ProjectsPage
         post={post}
         lang={post.langcode}
         events={events}
         bannerHeight="512px"
         projects={projects}
         promoted={promoted}
         buttonBanner="Fes voluntariat"
         toknowus={toknowus}
         //localSearchPages={localSearchPages}
         pageContext={pageContext}
         volunteering={volunteering}
        />

      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
query  PageProjects ($drupal_internal__nid: Int, $langcode: String!) {
      nodePageGoel(
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ) {
        langcode
        drupal_internal__nid
        id
        title
        drupal_id
        body {
          value
          summary
        }
        path{
          alias
        }
        created
        relationships {
          field_image {
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
        }
      }
    }
  }
  volunteering: allNodeVolunteering(
  filter: {
    status: {eq: true},
    langcode: {eq: $langcode}
    field_need_volunteers: {eq: true}
  }
) {
  edges {
    node {
      title
      field_when
      field_minimum
      field_duration_hours
      path {
        alias
      }
      sticky
      body {
        summary
        value
      }
      relationships {
        field_project {
          title
        }
        field_image {
          image_style_uri {
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
      }
    }
  }
}
  projects: allNodeProjectsGoel(
  filter: {
      langcode: {eq: $langcode}
      field_display_pages: {elemMatch: {drupal_internal__target_id: {eq: $drupal_internal__nid}}}
   }
) {
  edges {
    node {
      title
      field_image {
        title
      }
      path {
        alias
      }
      body {
        processed
        summary
        value
      }
      relationships {
        field_image {
          image_style_uri {
            _294x192
            _653_432
            _541x359
            large
            medium
            thumbnail
            wide
          }
        }
      }
    }
  }
}
}
`

export default PageProjects
